import React,{ useState, useContext, useEffect,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import DateRangePicker  from '../../Common/DateRangePicker'
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { GlobalContext } from '../../../contexts/GlobalContext';

const selectOption = [
  { label: '---', value: '1' },
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
  { label: '---', value: 1 },
]

const ResultSearchBox = ({isNotDefined, searchParams, onChangeSearchParams, handleClickSearch }) => {
    const {t, i18n} = useTranslation()
    const {sessionServerTimeStamp} = useContext(GlobalContext);

    const scSize = document.documentElement.clientWidth

    const handleClickDrawNumberOrDate = (value) => {
        onChangeSearchParams({...searchParams, drawSearchParamsType: value})
    }
    const handleClickDrawType = (value) => {
        onChangeSearchParams({...searchParams, drawType: value})
    }

    const onChangeDrawNumber = (value) => {
        onChangeSearchParams({...searchParams,
            drawNumber: parseInt(value),
            drawSearchParamsType: 'drawNumber'
        })
    }

    const handleChangeDate = (value) => {
        onChangeSearchParams({
            ...searchParams,
            drawSearchParamsType: 'drawDate',
            dateFrom:  value.startDate,
            dateTo: value.endDate
        })
    }

    const moreOptions = () => {
        const opts = []
        for (let i = 5; i <= 30; i++) {
          opts.push(<Dropdown.Item key={'down' + i}  onClick={()=> onChangeDrawNumber(i)} active={searchParams.drawNumber === i} value={i}>{i}</Dropdown.Item>)
        }
        return opts
      }

    return <div className='result-search-box'>
        <div className='draw-number-date'>
            <div className='draw-number-date-title'>{t('LB_M6_RESULTS_DRAWNUM_DATE')}</div>
            <div className='draw-number-date-content'>
                <div>
                    <label  className="radio-container" onClick={()=> handleClickDrawNumberOrDate('drawNumber')}>
                        <input type="radio" checked={searchParams.drawSearchParamsType === 'drawNumber'} className="invCalFlexiBet-betline" onChange={()=> handleClickDrawNumberOrDate('drawNumber')}/>
                        <span className="radio-checkmark "></span>
                    </label>
                    <div className='ddNoRSpace'>
                        <DropdownButton
                        className='draw-number-dropdown-button'
                        title={<div className='draw-number-dropdown-button-title'>
                            <span>{t('LB_M6_RESULTS_DRAW_NUM')}</span>
                            <div className='draw-number-dropdown-button-title-number'>{searchParams.drawNumber == 1 ? '---' : searchParams.drawNumber}</div><ArrowIcon direction='down' size={24}/>
                        </div>
                        }
                        align="end"
                        value={searchParams.drawNumber} >
                        {selectOption.map((item, index) => {
                            return <Dropdown.Item value={item.value} disabled={item.label==='---'} onClick={()=>onChangeDrawNumber(item.value)} active={searchParams.drawNumber === item.value} key={'top' + item.value+index}>{item.label}</Dropdown.Item>
                        })}
                        {moreOptions()}
                        </DropdownButton>
                    </div>
                </div>
                <div>
                    <label  className="radio-container" onClick={()=> handleClickDrawNumberOrDate('drawDate')}>
                        <input type="radio" checked={searchParams.drawSearchParamsType === 'drawDate'} className="invCalFlexiBet-betline" onChange={()=> handleClickDrawNumberOrDate('drawDate')}/>
                        <span className="radio-checkmark draw-date-radio"></span>
                    </label>
                    <div className='draw-date-input-container'>
                        <div className='draw-date-input'>
                            <div className='draw-date-input-tips'>{t('LB_M6_RESULTS_DRAW_Date_TIPS')}</div>
                            <DateRangePicker
                            onChangeDate={handleChangeDate}
                            defaultDate={{
                                startDate: searchParams.dateFrom,
                                endDate: searchParams.dateTo
                            }}
                            maxSelectMonths={3}
                            minDate={new Date('1993/1/1')}
                            maxDate={new Date(sessionServerTimeStamp)}
                            showMonthAndYearDropdown
                            className='date_range_picker date_range_marksix'
                            topValueLabel={t('LB_M6_RESULTS_DRAW_Date_TIPS')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='result-draw-type'>
            <div className='draw-number-date-title'>{t('LB_M6_RESULTS_DRAW_TYPE')}</div>
            <div>
                <div>
                    <div>
                        <label  className="radio-container" onClick={()=> handleClickDrawType('All')}>
                            <input type="radio" checked={searchParams.drawType === 'All'} className="invCalFlexiBet-betline" onChange={()=> handleClickDrawType('All')}/>
                            <span className="radio-checkmark"></span>
                            {t('LB_RC_TABLE_ALL')}
                        </label>
                    </div>
                    <div>
                        <label  className="radio-container" onClick={()=> handleClickDrawType('SnowBall')}>
                            <input type="radio" checked={searchParams.drawType === 'SnowBall'} className="invCalFlexiBet-betline" onChange={()=> handleClickDrawType('SnowBall')}/>
                            <span className="radio-checkmark"></span>
                            {t('LB_M6_SNOWBALL')}
                        </label>
                    </div>
                </div>
                <div >
                    <button className='search-btn cta_m6' onClick={handleClickSearch}>
                        {t('LB_M6_RESULTS_SEARCH')}
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default ResultSearchBox