import { useEffect, useContext,useState, useRef } from 'react';
import moment from 'moment';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { parseMessage } from '../useRacingPush/parseMessage';
import { useNavigate, useParams } from "react-router-dom";
import { getDefaultRacingUnitBet, isAllUpPage, isLastRaceRan, setDefaultDisplayOption,
         getNextDefinedRace, getRaceList, getDefaultMeeting  } from '../../Common/Common';
import { getRacingQuery, getQueryVariables, getResultsPageMeeting  } from '../DataQuery';
import { GetRaceConfig, GetRacingPageConfigs  } from '../../../Common/ConfigHelper';
import { useTranslation } from 'react-i18next';
import { ClearBetSels, GetBetSels, SetBetSels, validateBet, calBet } from '../../Common/RacingBetline';
import { isLocalMeeting } from '../../../Common/home-common';
import { useRacingFOUserContentQuery } from '../../../Home/Data/SitecoreDataHooks';
import useQueryString from '../../../Common/hooks/useQueryString';
import { useDataRefresh } from '../../../Racing/Data/DataRefreshHooks';
import useLoadMeeting from '../../../Racing/Data/useLoadMeeting';
import { useApolloClient } from '@apollo/client';
import { apiErrorCallback } from '../../../Common/graphqlFetch';
import { consoleLog } from '../../../Common/GlobalFunc';
import { getRacingTopics } from '../useRacingPush/common'

const useRCDataRefresh = (contentRef, setContent) => {
    const globalContext = useContext(GlobalContext);
    const { isPushing, tmpMsgQueue, replayMsgQueue } = globalContext.mqttClient;
    const {page} = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const foUserContent = useRacingFOUserContentQuery();
    const curProduct = 'racing';
    const queryString = useQueryString();
    const apolloClient = useApolloClient();
    const pushCounterRef = useRef(0)

    const resetInvCal = () => {
        let races = [];
        if ( isAllUpPage(contentRef.current.page) ) {
            races = contentRef.current.alupData.map(function (item) {
                return item.raceNo;
            })
        }
        else {
            races = getRaceList(contentRef.current.page, contentRef.current.raceNo, contentRef.current.meeting.poolInvs);
        }
        if (validateBet(contentRef, races, t, true)) {
            contentRef.current.betCount = calBet(contentRef);
            if ( contentRef.current.betCount > 0 ) {
                if ( contentRef.current.flexibet && contentRef.current.betTotal!='-' ) {
                    const unitBet = parseFloat(contentRef.current.betTotal / contentRef.current.betCount)
                    if(unitBet > 0.01){
                        contentRef.current.unitBet = Math.floor(unitBet * 100) / 100;
                    }else{
                        contentRef.current.unitBet = Math.floor(unitBet * 1000) / 1000 || Math.floor(unitBet * 10000) / 10000 || 0.0001;
                    }
                }
                else if (!contentRef.current.flexibet && contentRef.current.unitBet!='-') {
                    contentRef.current.betTotal = contentRef.current.unitBet * contentRef.current.betCount;
                }
            }
            else {
                contentRef.current.betCount = "-";
            }
        }
        else {
            contentRef.current.betCount = '-';
        }
        setContent({...contentRef.current});
    }

    const { reloadRacingPools } = useDataRefresh(contentRef, setContent, resetInvCal);
    const { reloadMeetingStatus, updatePushRunner } = useLoadMeeting(contentRef, setContent);
    const enableOddsPushRef = useRef(globalContext.globalState.commonCtrl.enableOddsPushButton)
    enableOddsPushRef.current = globalContext.globalState.commonCtrl.enableOddsPushButton && window.globalConfig.RC_ODDS_PUSH

    useEffect(()=> {
        contentRef.current.page = page!=null && page!='' ? page.toUpperCase() : "HOME";
        contentRef.current.betCount = '-';
        contentRef.current.pageConfig = GetRacingPageConfigs()[contentRef.current.page];
        contentRef.current.unitBet = getDefaultRacingUnitBet(contentRef.current.page);
        contentRef.current.betTotal = '-';
        contentRef.current.flexibet = false;
        contentRef.current.isLoading = true;
        loadQuery({ variables: ({
            page: contentRef.current.page,
            date: contentRef.current.date,
            venue: contentRef.current.venue,
            isPresales: contentRef.current.isPresales
        }) });
        setContent({...contentRef.current})
    }, [page, contentRef.current.update]);

    useEffect(() => {
        if ( ! enableOddsPushRef.current || !isPushing ) {
            clearInterval(contentRef.current.pushRefreshIntervalId);
            contentRef.current.pushRefreshIntervalId = null;
            pushCounterRef.current = 0
            if ( contentRef.current.autoRefreshIntervalId==null) {
                contentRef.current.autoRefreshIntervalId = setInterval(() => {
                    reloadRacingPools();
                    consoleLog("autorefresh contentRef", contentRef);
                }, contentRef.current.config["autoRefreshInterval"] * 1000);
                return () => {
                    clearInterval(contentRef.current.autoRefreshIntervalId);
                    contentRef.current.autoRefreshIntervalId = null;
                }
            }
        }
        else {
            clearInterval(contentRef.current.autoRefreshIntervalId);
            contentRef.current.autoRefreshIntervalId = null;

            if ( contentRef.current.pushRefreshIntervalId==null) {
                contentRef.current.pushRefreshIntervalId = setInterval(() => {
                    pushCounterRef.current += 1
                    const curDt = moment();
                    replayMsgQueue.current = replayMsgQueue.current.filter(item => curDt.diff(item.updTime, 'second') < 120);
                    let mQueue = [...tmpMsgQueue.current];
                    tmpMsgQueue.current = [];
                    mQueue.forEach(x=> {
                        parseMessage(x.topic, x.message, contentRef,t);

                    });
                    consoleLog("push contentRef", contentRef, "tmpQueue Cnt", mQueue.length, "replayQueue Cnt", replayMsgQueue.current.length);
                    resetInvCal()
                    if(pushCounterRef.current == 10){
                        let curTopics = getRacingTopics(contentRef.current)
                        if(curTopics.length > 0){
                            reloadMeetingStatus(updatePushRunner)
                        }
                        pushCounterRef.current = 0
                    }
                    setContent({...contentRef.current});
                }, 3000);
                return () => {
                    clearInterval(contentRef.current.pushRefreshIntervalId);
                    contentRef.current.pushRefreshIntervalId = null;
                }
            }
        }
    }, [isPushing]);

    useEffect(()=>{
        setContent(oldVal =>{
            return {
                ...oldVal,
                isPresales:false
            }
        })
    },[page])

    const handleChangePage = (_url, _page, _isAllup) => {
        navigate(_url);
        let betSels = _isAllup ? GetBetSels() : {};

        contentRef.current.page = _page.toUpperCase();
        contentRef.current.update = Math.random();
        contentRef.current.alupData = [];
        contentRef.current.alupFormula = "";
        contentRef.current.isLoading = true;
        setContent({...contentRef.current});

        globalContext.updateGlobalState({ betType: contentRef.current.page, jtcPara: [], allupBoxChecked: false });
        ClearBetSels();

        if (_isAllup) SetBetSels(betSels);
    }

    const loadQuery = (paras,callbackFunc) => {
        clearTimeout(window.RCMeetingFetchTimer)
        Promise.all([apolloClient.query({
            query : getRacingQuery(),
            variables: getQueryVariables(paras.variables),
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then( async ([results]) => {
            consoleLog("results",results);
            if ( results?.data?.raceMeetings?.[0]!=null ) {
                contentRef.current.meetingList = results.data.activeMeetings;
                let defaultMeeting = getDefaultMeeting( results.data.raceMeetings, results.data.timeOffset?.rc,GetRaceConfig());
                defaultMeeting.foPools = []
                defaultMeeting.resPools = []
                if(['RESULTS', 'HOME_RESULTS', 'JKC', 'TNC'].includes(contentRef.current.page)){
                    await loadResultsMeeting(defaultMeeting)
                }else{
                    contentRef.current.meeting = defaultMeeting
                }
                if(contentRef.current.meeting.date != contentRef.current.date){
                    contentRef.current.raceNo = 0;
                }
                contentRef.current.date = contentRef.current.meeting.date;
                contentRef.current.venue = contentRef.current.meeting.venueCode;
                getAllupPagePrevRaceNo();
                if(!['JKC', 'TNC'].includes(contentRef.current.page)) {
                    contentRef.current.raceNo = getNextDefinedRace(contentRef.current.page, contentRef.current.raceNo, contentRef.current.meeting);
                }
                contentRef.current.isLoading = false;
                contentRef.current.paraReady = true;
                if ( contentRef.current.displayOpt==null ) {
                    setDefaultDisplayOption(contentRef.current);
                }

                if ( contentRef.current.page=="HOME" && isLastRaceRan(contentRef.current.meeting) ) {
                    let url = "/" + i18n.language + "/racing/home_results/" + contentRef.current.date + "/" + contentRef.current.venue + "/" + contentRef.current.raceNo;
                    let page = "HOME_RESULTS";
                    handleChangePage(url, page, false);
                }else if(contentRef.current.page=="HOME_RESULTS" && contentRef.current.meeting && !isLastRaceRan(contentRef.current.meeting)){
                    let url = "/" + i18n.language + "/racing/home/" + contentRef.current.date + "/" + contentRef.current.venue + "/" + contentRef.current.raceNo;
                    let page = "HOME";
                    handleChangePage(url, page, false);
                }
                else if ( ["JKC", "TNC"].includes(contentRef.current.page) ) {
                    if(!isLocalMeeting(contentRef.current.venue)){
                        for ( let i=0; i<results.data.activeMeetings.length; i++ ) {
                            let mtg = results.data.activeMeetings[i];
                            if ( isLocalMeeting(mtg.venueCode) ) {
                                handleVenueClick(mtg.date, mtg.venueCode);
                                break;
                            }
                        }
                    }else{
                        foUserContent.loadFOUserContent();
                    }
                }

                let newUrl = `/${i18n.language}/racing/${contentRef.current.page.toLowerCase()}/${contentRef.current.date}/${contentRef.current.venue}/${contentRef.current.raceCacheNo || contentRef.current.raceNo}${queryString.toString()!="" ? `?${queryString.toString()}` : ''}`;
                navigate(newUrl, { replace: true });

                reloadRacingPools();

                globalContext.updateGlobalState({
                    product: curProduct,
                    betType: contentRef.current.page,
                    mtg: contentRef.current.meeting
                });
            }
            else{
                contentRef.current.isLoading = false
                contentRef.current.meetingList = [];
            }
            resetInvCal();
            //setInitLoading(false)
            contentRef.current.initLoading = false;
            setContent({ ...contentRef.current })
        }).catch(error => apiErrorCallback(error, ()=> {loadQuery(paras,callbackFunc)}, {timer: window.RCMeetingFetchTimer, disableRefetch: !enableOddsPushRef.current || !isPushing }));
    }

    const loadResultsMeeting = (raceMeeting) =>{
        return Promise.all([apolloClient.query({
            query : getResultsPageMeeting(),
            variables: getQueryVariables({
                page: contentRef.current.page,
                date: contentRef.current.date,
                venue: contentRef.current.venue
            }),
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then(([results]) => {
            if(results.errors){
                throw new Error(results.errors?.[0]?.message)
            }
            if(results?.data?.raceMeetings?.[0]){
                const currentMtg = results.data.raceMeetings.find(mtg =>{
                    return mtg.id == raceMeeting.id
                })
                contentRef.current.meeting = {
                    ...raceMeeting,
                    ...currentMtg
                }
            }
        })

    }


    const getAllupPagePrevRaceNo = () => {
        if(isAllUpPage(contentRef.current.page)){
            contentRef.current.raceCacheNo=contentRef.current.raceNo;
        } else if(!isAllUpPage(contentRef.current.page) && contentRef.current.raceCacheNo !== 0){
            contentRef.current.raceNo=contentRef.current.raceCacheNo;
            contentRef.current.raceCacheNo=0;
        } else {
            contentRef.current.raceCacheNo=0;
        }
    }

    const handleVenueClick = (dt, ve) => {
        ClearBetSels();
        contentRef.current.date = dt;
        contentRef.current.venue = ve;
        contentRef.current.raceNo = 1;
        contentRef.current.update = Math.random();
        contentRef.current.isLoading = true;
        contentRef.current.alupData = [];
        contentRef.current.alupFormula = "";
        contentRef.current.isPresales = false
        setContent({...contentRef.current});
        globalContext.resetGlobalState();
    }

    return {
        foUserContent,
        resetInvCal
    }

}

export default useRCDataRefresh