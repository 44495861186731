import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isLocalMeeting, NewWindow } from '../../Common/home-common';
import { GetRaceConfig, GetRacingComingleMsgs } from '../../Common/ConfigHelper';
import { getPersonalSettings } from '../../Home/PersonalSettings/config'
import { textFormatAmountFn } from '../../Common/GlobalFunc';
import { GetFirstBetType } from './RacingBetline';
import $ from 'jquery';
import { Cookies } from '../../Common/CookieHelper';

export function getDefaultRacingUnitBet(page) {
    const racSettings = getPersonalSettings('racing');
    if (['WP', 'WPQ'].includes(page)) return '-';
    if (racSettings[page]) return parseInt(racSettings[page], 10);
    return 10;
}

export function formatRacingDate(dt) {
    return dt.substring(8, 10) + '/' + dt.substring(5, 7) + '/' + dt.substring(0, 4);
}

export function isRacingFOPage(page) {
    return window.racingConfig["FO_PAGE"]?.includes(page);
}

export function getMeetingHeaderTitle(context, meeting, t, lang) {
    if ( meeting==null )
        return '';
    let dt = meeting.date;
    let formattedDt = formatRacingDate(dt);
    let ven = meeting.venueCode;
    if ( isLocalMeeting(meeting.venueCode) ) {
        ven = t('LB_' + meeting.venueCode);
    }
    else {
        // let race = meeting.races.filter(x=> x.no===context.content.raceNo)[0];
        let countryList = []
        countryList = meeting.country.sort((i, j) => {
            return i.seq - j.seq
        })
        if ( countryList.length > 0 ) {
            // ven = race['country_' + lang];
            ven = countryList.map(i => i['name' + lang]).join(' / ');
        }
    }
    return formattedDt + ", " + t('LB_' + meeting.dateOfWeek) + ", " + ven;
}

export function isPoolDefined(betType, raceNo, pools) {
    if (pools == null)
        return false;

    switch (betType) {
        case "HOME":
        case "RESULTS":
        case "HOME_RESULTS":
            return true;
        case "JKC":
        case "TNC":
            return false;  // check in isFOPoolDefined
        case "CROSS_ALUP":
            return pools.filter((data) => data.leg.races[0] == raceNo && ['WIN', 'PLA', 'QIN', 'QPL', 'FCT', 'TRI', 'CWA'].indexOf(data.oddsType)>=0).length>0;
        case "WP":
        case "WPALUP":
        case "PWIN":
        case "JTCOMBO":
            return pools.filter((data) => data.leg.races[0] == raceNo && ['WIN', 'PLA', 'WINPre', 'PLAPre'].indexOf(data.oddsType)>=0).length>0;
        case "W-P":
            return pools.filter((data) => data.leg.races[0] == raceNo && 'WIN'==data.oddsType).length>0
            && pools.filter((data) => data.leg.races[0] == raceNo && 'PLA'==data.oddsType).length>0;
        case "QQP":
            return pools.filter((data) => data.leg.races[0] == raceNo && 'QIN'==data.oddsType).length>0
            && pools.filter((data) => data.leg.races[0] == raceNo && 'QPL'==data.oddsType).length>0;
        case "WPQ":
        case "WPQALUP":
            return pools.filter((data) => data.leg.races[0] == raceNo && ['QIN', 'QPL', 'QINPre', 'QPLPre'].indexOf(data.oddsType)>=0).length>0;
        case "DBL":
            return pools.filter((data) => data.leg.races[0] == raceNo && ['DBL', 'DBLPre'].indexOf(data.oddsType)>=0).length>0;
        case "6UP":
            return pools.filter((data) => data.leg.races[0] == raceNo && ['SixUP'].indexOf(data.oddsType)>=0).length>0;
        case "FCTALUP":
            return pools.filter((data) => data.leg.races[0] == raceNo && ['FCT'].indexOf(data.oddsType)>=0).length>0;
        case "TRI":
        case "TRIALUP":
            return pools.filter((data) => data.leg.races[0] == raceNo && ['TRI', 'TRIPre'].indexOf(data.oddsType)>=0).length>0;
        case "IWN":
            return pools.filter((data) => data.leg.races[0] == raceNo && ['IWN', 'IWNPre'].indexOf(data.oddsType)>=0).length>0;
        case "FF":
            return pools.filter((data) => data.leg.races[0] == raceNo && ['FF', 'FFPre'].indexOf(data.oddsType)>=0).length>0;
        case "CWAALUP":
        case "CWAALLALUP":
            return pools.filter((data) => data.leg.races[0] == raceNo && ['CWA'].indexOf(data.oddsType)>=0).length>0;
        case "TURNOVER":
            return pools.filter((data) => data.leg.races[0] == raceNo).length>0;
        default:
            return pools.filter((data) => data.leg.races[0] == raceNo && betType==data.oddsType).length>0;
    }
}

export function isFOPoolDefined(betType, foPools) {
    if (foPools == null)
        return false;

    switch (betType) {
        case "JKC":
        case "TNC":
            return foPools.filter(x=>x.oddsType==betType).length > 0;
        default:
            return false;
    }
}

export function getPoolNotDefinedMsg(content, t) {
    const local =  isLocalMeeting(content.venue);
    const isNoBetService = content.meeting?.poolInvs.length === 0;
    if ( isNoBetService ) {
        return t("LB_RC_NO_BETTING_SERVICE");
    }

    switch (content.page) {
        case "CWA":
        case "CWAALUP":
        case "CWAALLALUP":
            if (local) {
                return t("LB_RC_POOL_NOT_DEFINED_CWA");
            }
            break;
        case "TURNOVER":
            return t("LB_RC_BETTING_NOT_OFFERED");
    }
    return t("LB_RC_POOL_NOT_DEFINED");
}

export function isSellingStarted(betType, raceNo, pools) {
    if (!isPoolDefined(betType, raceNo, pools))
        return false;
    const filterPool = pools.filter((data) => data.leg.races[0] == raceNo && data.oddsType==betType)[0];
    //return filterPool.status == "START_SELL" && filterPool.sellStatus == "START_SELL" ;
    return isStartSellpmPool(filterPool);
}

export function isRunnerSellingStarted(betType, raceNo, runnerNo, mtg) {
    if (!isSellingStarted(betType, raceNo, mtg.poolInvs))
        return false;

    let runners = mtg.races.filter((data) => data.no == raceNo)[0].runners;
    if (runners == null)
        return false;

    let r = runners.filter((data) => data.no == runnerNo && !data.status.toUpperCase().match(/^(SCRATCHED|SCRATCHEDRESERVED|STANDBY)$/))[0];
    return r != null;
}

export function getDefaultMeeting(mtgs, offset, config) {
    let tmpMtgs = mtgs.filter(x => !["ABANDON", "ABANDON_PARTIAL", "CLOSED"].includes(x.status.toUpperCase()) && x?.poolInvs.length > 0);
    if (tmpMtgs.length > 0) {
        let postTimes = [];
        const serverTime = window.ssTime || new Date().getTime();
        const curTime = moment(serverTime).add(offset || 0, 'seconds');
        tmpMtgs.forEach(tmpMtg => {
            tmpMtg.races.forEach(tmpRace => {
                postTimes.push({ mtg: tmpMtg, postTime: tmpRace.postTime, race: tmpRace });
            });
        });
        postTimes.sort((x, y) => {
            if (x.postTime == y.postTime) {
                const validVenue = config?.validVenue;
                return validVenue.indexOf(x.mtg.venueCode) - validVenue.indexOf(y.mtg.venueCode) ? -1 : 1;
            }
            return x.postTime > y.postTime ? -1 : 1;
        });
        let curTmpMtg = postTimes[postTimes.length - 1].mtg;
        postTimes.forEach(pt => {
            if (tmpMtgs.length == 1) {
                if (curTime < moment(pt.postTime)) {
                    curTmpMtg = pt.mtg;
                }
            } else {
                const isBettingOffer = pt.mtg.poolInvs.filter(x => x.leg.races[x.leg.races.length - 1] == pt?.race.no).length > 0;
                const hasRaceResultAndDividend = () => {
                    return (pt.race?.judgeSigns?.filter(x => x.value_en == "WEIGHED IN").length > 0
                        || !isBettingOffer) && pt.race.status == "RESULT";
                }
                if (!(['VOIDED', "ABANDONED"].includes(pt.race.status.toUpperCase()) || hasRaceResultAndDividend())) {
                    curTmpMtg = pt.mtg;
                }
            }
        });
        return curTmpMtg;
    }
    return mtgs[0];
}

export function getNextDefinedRace(page, raceNo, mtg) {
    let initRaces=[...mtg.races];
    if(raceNo == 0){
        let betOfferList=[];
        initRaces.map(item=>{
            if(mtg.poolInvs.filter(x=> x.leg.races[x.leg.races.length-1]==item.no).length > 0 ){
                betOfferList.push(item);
            }
        })
        if(betOfferList.length>0){
            initRaces=[...betOfferList];
        }
    }
    let curRaceNo = Math.min(...initRaces.map(x=>x.no));
    let ranRace = Math.max(...initRaces.filter(x=>["RESULT", 'CLOSED', 'VOIDED', "ABANDONED"].includes(x.status.toUpperCase())).map(x=>x.no));
    if ( raceNo > 0 ) {
        if ( isAllUpPage(page) ) {
            if ( isLastRaceRan(mtg) ) {
                curRaceNo = Math.max(...initRaces.map(x=>x.no));
            }
            else if ( raceNo<=ranRace ) {
                curRaceNo = ranRace+1;
            }
            else {
                curRaceNo = raceNo;
            }
        }
        else if ( initRaces.filter(x=>x.no===raceNo).length > 0)
            curRaceNo = raceNo;
    }
    else if ( mtg.currentNumberOfRace > 0 ) {
        curRaceNo = mtg.currentNumberOfRace;
    }

    if (mtg.poolInvs == null || mtg.poolInvs.length == 0) {
        return curRaceNo;
    }
    if ( isPoolDefined(page, curRaceNo, mtg.poolInvs) ) {
        return curRaceNo;
    }
    let maxRace = Math.max(...initRaces.map(x=>x.no));
    if ( isAllUpPage(page) ) {
        let alupStartRace = 0;
        for (let i = 1; i <= maxRace; i++) {
            if (i > ranRace && isPoolDefined(page, i, mtg.poolInvs)) {
                alupStartRace = i;
                break;
            }
        }
        if ( alupStartRace==0 ) {
            for (let i = maxRace; i >= 1; i--) {
                if (isPoolDefined(page, i, mtg.poolInvs)) {
                    alupStartRace = i;
                    break;
                }
            }
        }
        return alupStartRace;
    }
    else {
        for (let i = 1; i <= maxRace; i++) {
            if (isPoolDefined(page, i, mtg.poolInvs)) {
                return i;
            }
        }
    }

    return Math.min(...initRaces.map(x=>x.no));
}

export function isStartSell(mtg) {
    const startSellStatus = ["START_SELL", "STOP_SELL", "CLOSED", "CLOSED_AFTER_REFUND", "PAYOUT", "REFUND_BEFORE_CLOSE", "REFUND_AFTER_CLOSE"];
    return mtg.poolInvs.filter(x => startSellStatus.includes(x.status.toUpperCase())).length > 0;
}

export function isLastRaceRan(mtg) {
    if ( mtg==null ) return false;
    if ( mtg.status?.toUpperCase()?.indexOf("ABANDON")>=0 )
        return true;
    const lastRaceNo = Math.max(...mtg.races.map(x=>x.no))
    let lastRace = mtg.races.find(x=> x.no==lastRaceNo);
    return ['RESULT', 'CLOSED', 'VOIDED', "ABANDONED"].includes(lastRace?.status?.toUpperCase());
}

export function isVoidedRace(content, mtg, raceNo) {
    let pools = content.config.singleRacePool.split(';');
    let pmPoolsStatus = mtg.poolInvs.filter(x=> x.leg.races[0]===raceNo && pools.includes(x.oddsType)).map(x=>x.status);
    let isSinglePoolRefunded = pmPoolsStatus.length>0 && pmPoolsStatus.filter(x=>x.toUpperCase().indexOf("REFUND")>=0).length===pmPoolsStatus.length;
    return mtg.races.filter(x => x.no === raceNo)[0]?.status === 'VOIDED'
        ||  mtg.status == 'ABANDON' || (mtg.status == 'ABANDON_PARTIAL'  && mtg.races.filter(x => x.no === raceNo)[0]?.status != 'RESULT')
        || isSinglePoolRefunded;
}

export const isAbandonedRace = (mtg, raceNo) => {
    return ['ABANDONED'].includes(mtg.races.find(x => x.no === raceNo)?.status)
}

export function isAllUpPage(betType) {
    return GetRaceConfig()?.ALUP_PAGE?.includes(betType);
}

export function getRaceList(betType, raceNo, pools) {
    let r = [];
    switch (betType) {
        case "DBL":
        case "TBL":
        case "DT":
        case "TT":
            r = pools.filter((data) => data.leg.races[0] == raceNo && data.oddsType==betType)[0]?.leg?.races || [];
            break;
        case "6UP":
            r = pools.filter((data) => data.leg.races[0] == raceNo && data.oddsType=='SixUP')[0]?.leg?.races || [];
            break;
        default:
            r = [raceNo];
            break;
    }
    return r;
}

export function getComingleMsgs(mtg, betType, rNo) {
    let msgs = GetRacingComingleMsgs()?.[betType];
    let winPool = mtg.obSt.filter(data => data.oddsType=="WIN" && data.leg.races[0] == rNo)[0];
    let plaPool = mtg.obSt.filter(data => data.oddsType=="PLA" && data.leg.races[0] == rNo)[0];
    let tmpMsgs = null;
    let isWinComingle = winPool != null && (winPool.comingleStatus == "1" || winPool.comingleStatus == "2");
    let isPlaComingle = plaPool != null && (plaPool.comingleStatus == "1" || plaPool.comingleStatus == "2");

    if (isWinComingle && isPlaComingle)
        tmpMsgs = msgs["W-P"];
    else if (isWinComingle)
        tmpMsgs = msgs["WIN"];
    else if (isPlaComingle)
        tmpMsgs = msgs["PLA"];

    return tmpMsgs;
}

export function getComingleMsgsAlup(mtg, betType, selectedBetType, rNo) {

    let msgsList = GetRacingComingleMsgs()?.[betType];
    if ( msgsList== null )
        return null;

    let msgs = msgsList[selectedBetType];
    let winPool = mtg.obSt.filter(data => data.oddsType=="WIN" && data.leg.races[0] == rNo)[0];
    let plaPool = mtg.obSt.filter(data => data.oddsType=="PLA" && data.leg.races[0] == rNo)[0];
    let tmpMsgs = null;
    let isWinComingle = winPool != null && (winPool.comingleStatus == "1" || winPool.comingleStatus == "2");
    let isPlaComingle = plaPool != null && (plaPool.comingleStatus == "1" || plaPool.comingleStatus == "2");

    if (isWinComingle && isPlaComingle)
        tmpMsgs = msgs["W-P"];
    else if (isWinComingle)
        tmpMsgs = msgs["WIN"];
    else if (isPlaComingle)
        tmpMsgs = msgs["PLA"];

    return tmpMsgs;
}

export function getRefreshTime(mtg, context, showDate) {
    if (mtg==null)
        return null;

    let times = [];
    let pool = null;
    let races = [];
    const raceNo = context?.content?.raceNo;
    const preSuffix = context?.content?.isPresales ? "Pre" : "";
    switch ( context.content.page ) {
        case "JKC":
        case "TNC":
            pool = mtg.foPools?.filter(data => data.oddsType===context.content.page)[0];
            if ( pool ) {
                times.push(pool.lastUpdateTime);
            }
            break;
        case "TURNOVER":
            times = mtg.poolInvs.filter(x=> x.leg.races[0]===raceNo).map(x => x.lastUpdateTime);
            break;
        case "DBL":
        case "TBL":
        case "DT":
        case "TT":
            pool = mtg.pmPools.filter(x=>  x.leg.races[0]===raceNo && x.oddsType===context.content.page + preSuffix)[0];
            races = pool!=null ? pool.leg.races : [ raceNo ];
            times = mtg.pmPools.filter(x=> races.includes(x.leg.races[0]) && x.oddsType==="WIN" + preSuffix).map(x => x.lastUpdateTime);
            if ( pool ) {
                times.push(pool.lastUpdateTime);
            }
            break;
        case "6UP":
            pool = mtg.pmPools.filter(x=>  x.leg.races[0]===raceNo && x.oddsType==="SixUP")[0];
            races = pool!=null ? pool.leg.races : [ raceNo ];
            times = mtg.pmPools.filter(x=> races.includes(x.leg.races[0]) && x.oddsType==="WIN").map(x => x.lastUpdateTime);
            if ( pool ) {
                times.push(pool.lastUpdateTime);
            }
            break;
        case "WPQ":
            times = [...mtg.pmPools.filter(x=> x.leg.races[0]===raceNo && x.oddsType==="WIN" + preSuffix).map(x => x.lastUpdateTime),
            ...mtg.pmPools.filter(x=> x.leg.races[0]===raceNo && x.oddsType==="PLA" + preSuffix).map(x => x.lastUpdateTime),
            ...mtg.pmPools.filter(x=> x.leg.races[0]===raceNo && x.oddsType==="QIN" + preSuffix).map(x => x.lastUpdateTime),
            ...mtg.pmPools.filter(x=> x.leg.races[0]===raceNo && x.oddsType==="QPL" + preSuffix).map(x => x.lastUpdateTime)];
            break;
        case "WP":

            times = [...mtg.pmPools.filter(x=> x.leg.races[0]===raceNo && x.oddsType==="WIN" + preSuffix).map(x => x.lastUpdateTime),
            ...mtg.pmPools.filter(x=> x.leg.races[0]===raceNo && x.oddsType==="PLA" + preSuffix).map(x => x.lastUpdateTime)];
            break;
        case "CROSS_ALUP":
        case "WPALUP":
        case "CWAALUP":
        case "WPQALUP":
        case "FCTALUP":
        case "TRIALUP":
            times = mtg.pmPools.filter(x=> x.oddsType==="WIN").map(x => x.lastUpdateTime);
            break;
            case "CWA":
        case "CWB":
        case "CWC":
        case "IWN":
        case "FCT":
        case "TCE":
        case "QTT":
        case "TRI":
        case "FF":
            times = [...mtg.pmPools.filter(x=> x.leg.races[0]===raceNo && x.oddsType==="WIN" + preSuffix).map(x => x.lastUpdateTime),
            ...mtg.pmPools.filter(x=> x.leg.races[0]===raceNo && x.oddsType===context.content.page + preSuffix).map(x => x.lastUpdateTime)];
            break;
        default:
            times = mtg.pmPools.filter(x=> x.leg.races[0]===raceNo && x.oddsType==="WIN" + preSuffix).map(x => x.lastUpdateTime);
            break;
    }

    if ( times.length > 0 ) {
        times.sort((x,y)=> y < x ? -1 : 1);
        if ( times[0]!=null && times[0]!="" ) {
            let tObj = moment(times[0]);
            return showDate ? tObj.format("DD/MM/YYYY HH:mm") : tObj.format("HH:mm");
        }
    }
    return '';
}

export function initAlupData(contentRef, raceNo, list) {
    for ( let idx=0; idx<list.length; idx++ ) {
        if ( list[idx].show && isPoolDefined(list[idx].type, raceNo, contentRef.current.meeting.poolInvs) ) {
            const subType = contentRef.current.subMethod?.type && contentRef.current.raceNo === raceNo ? contentRef.current.subMethod.type  : 'S'
            return { raceNo: raceNo, betType: GetFirstBetType() || list[idx].type, subType, maxLeg: list[idx].maxLeg };
        }
    }
    return null;
}

export function setDefaultDisplayOption(content) {
    $('.current-odds').show();
    switch( content.page ) {
        case "WPQ":
            let race = content.meetingList.find(x=>x.venueCode===content.venue)?.races.find(x=> x.no===content.raceNo);
            if ( race?.wageringFieldSize>14 ) {
                content.displayOpt = "top";  // for QIN
                content.displayOpt2 = "top"; // for QPL
            }
            else {
                content.displayOpt = "";
                content.displayOpt2 = "";
            }
            break;
        case "TCE":
        case "TRI":
        case "FF":
        case "QTT":
            content.displayOpt = "top";
            content.displayOpt2 = "";   // no use
            $('.current-odds').hide();
            break;
        default:
            content.displayOpt = "";
            content.displayOpt2 = "";
            break;
    }
}

export function translateChangesItem(t, item, lang, showRaceNo) {
    let msg = "";
    switch (item.type) {
        case "92":
            msg = (showRaceNo ? t('LB_RACE_CHANGES_RACE').replace('{0}', item.raceNo) : "")
                + t('LB_RACE_CHANGES_92')
                .replace('{1}', item.runnerNo)
                .replace('{2}', item["horseName_" + lang]);
            break;
        case "96":
            msg = (showRaceNo ? t('LB_RACE_CHANGES_RACE').replace('{0}', item.raceNo) : "")
                + t('LB_RACE_CHANGES_96')
                .replace('{1}', item.runnerNo)
                .replace('{2}', item["scratchHorseName_" + lang])
                .replace('{3}', item["horseName_" + lang]);
            break;
        case "400":
            msg = (showRaceNo ? t('LB_RACE_CHANGES_RACE').replace('{0}', item.raceNo) : "")
                + t('LB_RACE_CHANGES_400')
                .replace('{1}', item.runnerNo)
                .replace('{2}', "R"==item.scrResvIndicator ? t('LB_RACE_CHANGES_RES'): "")
                .replace('{3}', item["horseName_" + lang]);
            break;
        case "98":
            msg = (showRaceNo ? t('LB_RACE_CHANGES_RACE').replace('{0}', item.raceNo) : "")
                + t('LB_RACE_CHANGES_98')
                .replace('{1}', item.runnerNo)
                .replace('{2}', item["horseName_" + lang])
                .replace('{3}', item.handicapWeight);
            break;
        case "99":
            msg = (showRaceNo ? t('LB_RACE_CHANGES_RACE').replace('{0}', item.raceNo) : "")
                + t('LB_RACE_CHANGES_99')
                .replace('{1}', item.runnerNo)
                .replace('{2}', item["horseName_" + lang])
                .replace('{3}', item["jockeyName_" + lang]);
            break;
        case "169":
            if ("N"==item.scrResvIndicator) {
                msg = (showRaceNo ? t('LB_RACE_CHANGES_RACE').replace('{0}', item.raceNo) : "")
                + t('LB_RACE_CHANGES_169')
                .replace('{1}', item.runnerNo)
                .replace('{2}', item["horseName_" + lang]);
            }
            break;
    }
    return msg;
}

export function filterChangeItems(item) {
    switch (item.type) {
        case "92":
        case "96":
        case "400":
        case "98":
        case "99":
            return true;
        case "169":
            if ("N"==item.scrResvIndicator) {
                return true;
            }
            break;
    }
    return false;
}

export const sortChanges = (x,y) => {
    if ( x.raceNo != y.raceNo ) {
        return x.raceNo - y.raceNo;
    }
    else if ( x.time != y.time ) {
        return y.time < x.time ? -1 : 1;
    }
    return parseInt(x.runnerNo) - parseInt(y.runnerNo);
}

export const PenetroReading = ({reading, t, path}) => {
    if (reading!=null) {
        let pTime = moment(reading.readingTime).format("hh:mm A");
        return <>
            <div className={path?"font-weight500":""}>{t('LB_RACE_INFO_PREADING')}</div>
            <div>{reading.reading} ({t('LB_RACE_INFO_ASOF')} <span style={{whiteSpace: "nowrap"}}>{pTime}</span>)</div>
        </>
    }
    return <></>;
}

export const HammerReading = ({reading, t, path}) => {
    if (reading!=null) {
        let pTime = moment(reading.readingTime).format("hh:mm A");
        return <>
            <div className={path?"font-weight500":""}>{t('LB_RACE_INFO_HREADING')}</div>
            <div>{reading.reading} ({t('LB_RACE_INFO_ASOF')} <span style={{whiteSpace: "nowrap"}}>{pTime}</span>)</div>
        </>
    }
    return <></>;
}

export const showWorldPoolLogoLanding = (mtg) => {
    return window.racingConfig.enableWPBanner && isLocalMeeting(mtg.venueCode) && mtg.races.some(raceObj => raceObj.claCode=="G1");
}

export const JackPotInfo = ({ mtg, betType }) => {
    const { t } = useTranslation();
    let entry = [];
    let maxRace = Math.max(...mtg.races.map(x=>x.no));
    for (let i = 1; i <= maxRace; i++) {
        let pool = mtg.jpEsts.filter(item => item.leg.races[0] == i && item.oddsType == betType && item.jackpot != null && item.jackpot != "")[0];
        if (pool != null) {
            entry.push(<div className="content">
                <div>{t('LB_RC_ODDS_TABLE_RACENO')}: {i}</div>
                <div className="font-color-red">${parseInt(pool.jackpot).toLocaleString()}</div>
            </div>);
        }
    }
    if (entry.length > 0) {
        return <div className="jpEstTable">
            <div className="content">
                <div >{t(betType == 'FF' ? 'LB_RC_QTTFF_MERGE' : 'LB_RC_TCETRI_MERGE')}</div>
                <div >{t('LB_RC_JACKPOT')}</div>
            </div>
            <hr />
            {entry}
        </div>
    }
    return null;
}

const EstDivInfoByType = ({ betType, mtg }) => {
    const { t } = useTranslation();
    let entry = [];
    let lbl = betType==='SixUP' ? t('LB_6UP_WIN_BONUS') : t('LB_' + betType);
    let hasMultiPools = betType==="DT" || mtg.jpEsts.filter(item => item.oddsType == betType).length > 1;
    let maxRace = Math.max(...mtg.races.map(x=>x.no));
    for (let i = 1; i <= maxRace; i++) {
        let pool = mtg.jpEsts.filter(item => item.leg.races[0] == i && item.oddsType == betType && item.estimatedDividend != null && item.estimatedDividend != "")[0];
        if (pool != null) {
            entry.push(<div className="content">
                <div>{hasMultiPools && pool.leg.number >0 ? t('LB_RC_POOL_NO_' + pool.leg.number) : ''}{lbl}</div>
                <div className="font-color-red">${parseInt(pool.estimatedDividend).toLocaleString()}</div>
            </div>);
        }
    }
    return entry;
}

export const EstDivInfo = ({ mtg }) => {
    const { t } = useTranslation();
    let entry = [];
    entry = entry.concat(<EstDivInfoByType betType="TT" mtg={mtg} />);
    entry = entry.concat(<EstDivInfoByType betType="SixUP" mtg={mtg} />);
    entry = entry.concat(<EstDivInfoByType betType="DT" mtg={mtg} />);
    if (hasEst(mtg)) {
        return <div className="jpEstTable">
            <div className="content">
                <div >{t('LB_RACE_INFO_BLOCK_POOL')}</div>
                <div >{t('LB_RACE_INFO_BLOCK_ED')}</div>
            </div>
            <hr />
            {entry}
        </div>

    }
    return null;
}

export const Changes = ({ mtg }) => {
    const { t, i18n } = useTranslation();
    const filterHistories = mtg.changeHistories.filter(x=> filterChangeItems(x));

    if ( filterHistories.length==0 ) {
        return <div>{t('LB_RACE_CHANGES_NOCHG')}</div>
    }
    filterHistories.sort(sortChanges);

    const onClickChangeHis = () => {
        const url = window.globalConfig.JCRW_URL + t('URL_RC_JCRW_CHANGES');
        redirectJCRW(url);
    }

    return filterHistories.slice(0,3).map((item) => {
        let msg = `${t('LB_' + mtg.venueCode)}${i18n.language=="en" ? " " : ""}${translateChangesItem(t, item, i18n.language, true)} (${moment(item.time).format("DD/MM HH:mm")})`;
        //return <div onClick={()=> { NewWindow(`${window.globalConfig.JCRW_URL}${t('URL_RC_JCRW_CHANGES')}`, '', 770, 500, 1, 1); }} className="racingTopChangesRow">&gt; {msg}</div>
        return <div onClick={ onClickChangeHis} className="racingTopChangesRow">&gt; {msg}</div>
    });
}

export const hasJpt = (mtg) => {
    return mtg.jpEsts.filter(item => ["TRI", "FF"].includes(item.oddsType) && item.jackpot != null && item.jackpot != "").length > 0;
}

export const hasEst = (mtg) => {
    return mtg.jpEsts.filter(item => ["DT", "TT", "SixUP"].includes(item.oddsType) && item.estimatedDividend != null && item.estimatedDividend != "").length > 0;
}

export const getMaxRunnerSize = (context, legList, start, end) => {
    const mtg = context.content.meeting;
    let num = 0;
    legList.slice(start, end).forEach((_cItem, _cIndex) => {
        let race = mtg.races.filter(x=> x.no===_cItem)[0];
        let rLen = race?.runners.filter((data) => !!data.no ).length;
        num = Math.max(num, rLen);
    });
    return num;
}


export const unitBetText = (unitBet, isFlexBet) =>{
    if(unitBet === '') return ''
    if(unitBet === '-') return unitBet
    if(!isFlexBet){
        return textFormatAmountFn(unitBet, 0, false)
    }else{
        if(unitBet <= 0.0001) return 0.0001
        else if(unitBet >= 0.01) {
            const newBet = unitBet
            return textFormatAmountFn(newBet, 2, false)
        }else{
            const newBet = Math.floor(unitBet * 1000) / 1000 || Math.floor(unitBet * 10000) / 10000
            return newBet
        }
    }
}
export const redirectJCRW = (url) =>{

    const currUrl = new URL(window.location.href);
    const subdomains = currUrl.hostname.split('.').reverse();
    const domainValue = subdomains[1];
    if(window.racingConfig["redirectIsReplaceWindow"]){

        window.removeEventListener('beforeunload', window.secDialogFn);
        let dt = moment().format('yyyy-mm-dd hh:MM:ss');
        const domainStr = '.' + domainValue + '.com';
        Cookies.setData('isSP4e', true, domainStr);
        Cookies.setData('lastURL4e', window.location.href, domainStr);
        Cookies.setData('lastURL4eAccessDt', dt, domainStr);
        sessionStorage.setItem('1stTimeFromJCRWRedirect', true);

        console.debug('redirecting to jcrw...')
        window.location.href = url;

    }
    else{
        NewWindow(url, '', 770, 500, 1, 1);
    }
}

export function isStartSellpmPool(pool){
    if(pool?.status?.toUpperCase() === "START_SELL" && pool?.sellStatus?.toUpperCase() === "START_SELL"){
        return true;
    }
    return false;
}

export const isLiveRacingWhitelisted = () => {
    return sessionStorage.getItem('ELVA') === '1' && sessionStorage.getItem('have_webcast_access')=="Y";
}

export const getPoolInvBetTypes = (content) =>{

    let suf = content.isPresales ? "Pre" : "";
    let page = content.page
    switch (page) {
        case "WP":
            return ["WIN","PLA","QIN","QPL","CWA","CWB","CWC",
            "IWN","FCT","TCE","TRI","FF","QTT","DBL","TBL","DT","TT","SixUP"]
        case "JTCOMBO":
        case "PWIN":
            return ["WIN","PLA"]
        case "WPQ":
            return ["WIN","PLA","QIN","QPL"]
        case "CWA":
        case "CWB":
        case "CWC":
        case "FCT":
        case "TCE":
        case "QTT":
        case "TRI":
        case "FF":
        case "DBL":
        case "TBL":
        case "DT":
        case "TT":
            return ["WIN", page]
        case "IWN":
            return ["WIN" + suf, page + suf]
        case "6UP":
            return ["SixUP", "WIN"]
        case "CWAALUP":
        case "CWAALLALUP":
        case "CROSS_ALUP":
        case "WPALUP":
        case "WPQALUP":
        case "FCTALUP":
        case "TRIALUP":
            return ["WIN","PLA","QIN","QPL","CWA","CWB","CWC","FCT","TRI"]
        case "JKC":
        case "TNC":
            return [page]
        case 'TURNOVER':
            return ["WIN","PLA","QIN","QPL","CWA","CWB","CWC",
            "IWN","FCT","TCE","TRI","FF","QTT","DBL","TBL","DT","TT","SixUP"]
        default:
            break;
    }
}

export default function secDialogFn(e){
    if (e) e.returnValue = 'any message';
    return 'any message';
}

window.secDialogFn  = secDialogFn;